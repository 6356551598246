import {
  Anchor,
  Avatar,
  Card,
  Flex,
  Grid,
  Space,
  Text,
  Title,
} from "@mantine/core";
import { IconLink } from "@tabler/icons-react";
import moment from "moment";
import React from "react";

function CompanyNews({ stockNews }) {
  const news = stockNews[0];

  return (
    <>
      <Card withBorder>
        <Title order={5} c="dimmed">
          News
        </Title>
        <Space h="xl" />
        {news?.map((item, index) => (
          <>
            <Flex gap={20}>
              <Anchor href={item.url} target="_blank">
                <Avatar src={item.image} alt={item.symbol} />
              </Anchor>

              <div style={{ width: "50rem" }}>
                <Text fz="sm" fw={600}>
                  {item.site} -{" "}
                  {moment(item.publishedDate).format("dddd, DD, YYYY h:mm a")}
                </Text>
                <Text size="sm">
                  {item.title}. {item.text}
                </Text>
                <Anchor size="sm" href={item.url} target="_blank">
                  Read more
                </Anchor>
              </div>
            </Flex>

            <Space h="xl" />
          </>
        ))}
      </Card>
    </>
  );
}

export default CompanyNews;

import {
  ActionIcon,
  AppShell,
  Box,
  Burger,
  Button,
  Flex,
  Group,
  Text,
  ScrollArea,
  Avatar,
  Menu,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconLogout, IconPlus } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import GetFullQuote from "../APIs/GetFullQuote";
import { fetchUserAttributes } from "aws-amplify/auth";
import { useEffect, useState } from "react";

export default function Home({ signOut, user }) {
  const navigate = useNavigate();
  const [attributes, setAttributes] = useState([]);

  const [mobileOpened, { toggle: toggleMobile }] = useDisclosure();
  const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure(true);
  const [opened, { toggle }] = useDisclosure();

  const handleHomePage = () => {
    navigate("/");
  };

  useEffect(() => {
    async function fetchUser() {
      try {
        const res = await fetchUserAttributes();
        setAttributes([res]);
      } catch (error) {
        console.log(error);
      }
    }

    fetchUser();
  }, []);

  return (
    <>
      <AppShell
        header={{ height: 60 }}
        navbar={{
          width: 300,
          breakpoint: "sm",
          collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
        }}
        padding="md"
      >
        <AppShell.Header>
          <Flex
            ml={20}
            mr={20}
            h={60}
            justify="flex-start"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Burger
              opened={opened}
              onClick={toggle}
              hiddenFrom="sm"
              size="md"
              mr={20}
            />

            <Flex
              align="center"
              style={{ cursor: "pointer" }}
              onClick={handleHomePage}
            >
              <Text size="xl" fw={500}>
                Insight
              </Text>
              <Text c="green" fw={500} size="xl">
                Terminal
              </Text>
            </Flex>

            <Box style={{ flexGrow: 1 }} />
            <Group>
              <Menu>
                {attributes.map((user) => (
                  <Menu.Target key={user.email} style={{ cursor: "pointer" }}>
                    <Flex align="center" gap={10}>
                      <Avatar />
                      <Text>
                        {user.given_name} {user.family_name}
                      </Text>
                    </Flex>
                  </Menu.Target>
                ))}

                <Menu.Dropdown>
                  <Menu.Item onClick={signOut}>Sign out</Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Group>
          </Flex>
        </AppShell.Header>

        <AppShell.Navbar p="md">
          <AppShell.Section>
            <Group justify="space-between">
              <Text c="dimmed" fw={600}>
                My watch list
              </Text>
              <ActionIcon variant="subtle">
                <IconPlus />
              </ActionIcon>
            </Group>
          </AppShell.Section>
          <AppShell.Section grow component={ScrollArea}></AppShell.Section>
        </AppShell.Navbar>

        <AppShell.Main>
          <GetFullQuote />
        </AppShell.Main>
      </AppShell>
    </>
  );
}

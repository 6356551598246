import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/dropzone/styles.css";
import "@mantine/code-highlight/styles.css";
import "@mantine/notifications/styles.css";
import "@aws-amplify/ui-react/styles.css";
import { Notifications } from "@mantine/notifications";
import App from "./App";
import { createTheme, MantineProvider } from "@mantine/core";
import { Amplify } from "aws-amplify";
import config from "./amplifyconfiguration.json";
Amplify.configure(config);

const theme = createTheme({
  /** Put your mantine theme override here */
  primaryColor: "green",
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <MantineProvider theme={theme}>
    <Notifications />
    <App />
  </MantineProvider>
);

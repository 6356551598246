import {
  ActionIcon,
  Avatar,
  Box,
  Card,
  Divider,
  Flex,
  Grid,
  GridCol,
  Group,
  Image,
  LoadingOverlay,
  NumberFormatter,
  Space,
  Spoiler,
  Stack,
  Tabs,
  Text,
  Title,
} from "@mantine/core";
import {
  IconCaretDownFilled,
  IconCaretUpFilled,
  IconPlus,
  IconTarget,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import SearchTicker from "./SearchTicker";
import moment from "moment";
import CompanyNews from "../components/CompanyNews";
import GetPriceTarget from "./GetPriceTarget";
import GetInsiderTransactions from "./GetInsiderTransactions";
import GetEconomicCal from "./GetEconomicCal";

const key = process.env.REACT_APP_FMP_API;

const cardHeight = "100%";

function GetFullQuote() {
  const [loading, setLoading] = useState(false);
  const [userSelectedSymbol, setUserSelectedSymbol] = useState("");

  const [profile, setProfile] = useState([]);
  const [dividendData, setDividendData] = useState([]);
  const [metrics, setMetrics] = useState([]);
  const [stockNews, setStockNews] = useState([]);
  const [aboutLineClamp, setAboutLineClamp] = useState(2);
  const [percentChange, setPercentChange] = useState("");

  const [priceTarget, setPriceTarget] = useState([]);
  const [earningsData, setEarningsData] = useState([]);

  // const a = priceTarget((item) => item.targetConsensus);
  // const b = profile((item) => item.price);
  // const math = parseInt(a / b) * 100;
  // const percent = parseFloat(math).toFixed(2);
  // console.log(percent);

  async function fetchPriceTarget(selectedSymbol) {
    try {
      const response = await fetch(
        `https://financialmodelingprep.com/api/v4/price-target-consensus?symbol=${selectedSymbol}&apikey=${key}`,
        {
          method: "GET",
        }
      );
      const res = await response.json();
      setPriceTarget(res);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  }
  async function fetchEarnings(selectedSymbol) {
    try {
      const response = await fetch(
        `https://financialmodelingprep.com/api/v3/historical/earning_calendar/${selectedSymbol}?apikey=${key}`,
        {
          method: "GET",
        }
      );
      const res = await response.json();
      const reversed = res.reverse();
      setEarningsData(reversed);
      console.log(reversed);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchCompanyOutlook(selectedSymbol) {
    setUserSelectedSymbol(selectedSymbol);
    setLoading(true);
    setPercentChange("");
    setAboutLineClamp(2);

    fetchPriceTarget(selectedSymbol);
    fetchEarnings(selectedSymbol);

    try {
      const response = await fetch(
        `https://financialmodelingprep.com/api/v4/company-outlook?symbol=${selectedSymbol}&apikey=${key}`,
        {
          method: "GET",
        }
      );
      const res = await response.json();

      setProfile([res.profile]);
      setMetrics([res.metrics]);
      setStockNews([res.stockNews]);
      if (res.stockDividend.length !== 0) {
        setDividendData([res.stockDividend[0]]);
      } else {
        setDividendData([]);
      }

      //Calculate percentage changes
      let price = [res.profile].map((item) => item.price);
      let changes = [res.profile].map((item) => item.changes);
      let difference = price - changes;
      let percent = (changes / difference) * 100;
      setPercentChange(percent);
      //

      console.log(res);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  const data = profile.map((item) => (
    <div key={item}>
      <Grid>
        <GridCol span={12}>
          <Flex>
            <Group gap={20}>
              <Image
                h={50}
                fit="contain"
                width="auto"
                src={item.image}
                alt={item.companyName}
              />

              <Stack align="flex-start" gap={0}>
                <Title order={3}>{item.symbol}</Title>
                <Text truncate="end">{item.companyName}</Text>
              </Stack>
            </Group>

            <div style={{ flexGrow: 1 }} />

            <div style={{ position: "absolute", right: 20 }}>
              <ActionIcon variant="subtle">
                <IconPlus />
              </ActionIcon>
            </div>
          </Flex>
        </GridCol>

        <GridCol span={{ base: 12, md: 12, lg: 12 }}>
          <Stack align="flex-start" gap={1}>
            <Title order={2}>
              {item.price.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Title>

            <Group gap={5}>
              {item.changes < 0 ? (
                <>
                  {/* This converts to string to remove negative symbol */}

                  <Flex align="center">
                    <ActionIcon c="red" variant="transparent">
                      <IconCaretDownFilled />
                    </ActionIcon>
                    <Text size="sm" c="red">
                      $
                      {parseFloat(item.changes)
                        .toFixed(2)
                        .slice(1)
                        .toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                        .replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1,")}
                    </Text>
                  </Flex>

                  <Text size="sm" c="red">
                    (
                    {String(parseFloat(percentChange).toFixed(2))
                      .slice(1)
                      .replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1,")}
                    %)
                  </Text>
                </>
              ) : (
                <>
                  {/* This converts to string to remove negative symbol */}
                  <Flex align="center">
                    <ActionIcon c="green" variant="transparent">
                      <IconCaretUpFilled />
                    </ActionIcon>
                    <Text size="sm" c="green">
                      $
                      {parseFloat(item.changes)
                        .toFixed(2)

                        .toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                        .replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1,")}
                    </Text>
                  </Flex>

                  <Text size="sm" c="green">
                    (
                    {String(parseFloat(percentChange).toFixed(2)).replace(
                      /(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g,
                      "$1,"
                    )}
                    %)
                  </Text>
                </>
              )}

              <Text size="sm">Today</Text>
            </Group>
          </Stack>
          <Space h="lg" />
          <Grid mih={150}>
            <GridCol span={{ base: 12, md: 4, lg: 4 }}>
              <Card withBorder mih={cardHeight}>
                <Text c="blue" fw={500}>
                  Price target
                </Text>

                {priceTarget.map((price) => (
                  <div key={price.consensus}>
                    <Text fz="md">${price.targetConsensus}</Text>
                    {price.targetConsensus - item.price > 0 ? (
                      <Text fz="sm" c="green">
                        {(
                          parseInt(price.targetConsensus - item.price) /
                          item.price
                        ).toFixed(2) * 100}
                        % upside
                      </Text>
                    ) : (
                      <Text fz="sm" c={"red"}>
                        {(
                          parseInt(price.targetConsensus - item.price) /
                          item.price
                        ).toFixed(2) * 100}
                        % downside
                      </Text>
                    )}
                  </div>
                ))}
              </Card>
            </GridCol>
            <GridCol span={{ base: 12, md: 4, lg: 4 }}>
              <Card withBorder mih={cardHeight}>
                <Text c="orange" fw={500}>
                  Earnings report
                </Text>

                {earningsData.map((earnings, index) => (
                  <div key={index}>
                    <Text fz="md">
                      {earnings.date >
                        moment().subtract(1, "days").format("YYYY-MM-DD") &&
                        earnings.date <
                          moment().add(100, "days").format("YYYY-MM-DD") &&
                        moment(earnings.date).format("MMM DD, YYYY")}
                    </Text>
                    <Text fz="sm" c="dimmed">
                      {earnings.date >
                        moment().subtract(1, "days").format("YYYY-MM-DD") &&
                        earnings.date <
                          moment().add(100, "days").format("YYYY-MM-DD") &&
                        earnings.time}
                    </Text>
                  </div>
                ))}
              </Card>
            </GridCol>
            <GridCol span={{ base: 12, md: 4, lg: 4 }}>
              <Card withBorder mih={cardHeight}>
                <Text c="lime" fw={500}>
                  Ex-dividend
                </Text>

                {dividendData.length === 0 ? (
                  <Text fz="md" c="dimmed">
                    No upcoming dividends
                  </Text>
                ) : (
                  dividendData.map((item, index) => (
                    <div key={index}>
                      <Text fz="md">
                        {moment(item.date).format("MMM DD, YYYY")}
                      </Text>
                      <Text fz="sm" c="dimmed">
                        ${parseFloat(item.dividend).toFixed(2)}
                      </Text>
                    </div>
                  ))
                )}
              </Card>
            </GridCol>
          </Grid>
        </GridCol>

        <GridCol>
          <Spoiler
            maxHeight={70}
            showLabel={<Text size="sm">Show more</Text>}
            hideLabel={<Text size="sm">Show less</Text>}
          >
            <Stack gap={5}>
              <Text fw={600}>About</Text>
              <Text size="sm">{item.description}</Text>
            </Stack>
          </Spoiler>

          <Space h="md" />

          <Grid mt={20}>
            <GridCol span={{ base: 6, md: 6, lg: 6 }}>
              <div>
                <Text c="dimmed" size="sm">
                  CEO
                </Text>
                <Text size="sm">{item.ceo}</Text>
              </div>
            </GridCol>
            <GridCol span={{ base: 6, md: 6, lg: 6 }}>
              <div>
                <Text c="dimmed" size="sm">
                  IPO Date
                </Text>
                <Text size="sm">
                  {moment(item.ipoDate).format("MMMM DD, YYYY")}
                </Text>
              </div>
            </GridCol>
            <GridCol span={{ base: 6, md: 6, lg: 6 }}>
              <div>
                <Text c="dimmed" size="sm">
                  Employees
                </Text>
                <Text size="sm">
                  <NumberFormatter
                    thousandSeparator
                    value={item.fullTimeEmployees}
                  />
                </Text>
              </div>
            </GridCol>
            <GridCol span={{ base: 6, md: 6, lg: 6 }}>
              <div>
                <Text c="dimmed" size="sm">
                  Headquarters
                </Text>
                <Text size="sm">
                  {item.city} - {item.state} ({item.country})
                </Text>
              </div>
            </GridCol>
            <GridCol span={{ base: 6, md: 6, lg: 6 }}>
              <div>
                <Text c="dimmed" size="sm">
                  Market cap
                </Text>
                <Text size="sm">
                  {item.mktCap.toLocaleString("en-US", {
                    currency: "USD",
                    notation: "compact",
                  })}
                </Text>
              </div>
            </GridCol>
          </Grid>
          <Divider h="md" mt="md" />

          <Grid>
            <GridCol span={{ base: 6, md: 6, lg: 6 }}>
              <div>
                <Text c="dimmed" size="sm">
                  52-wk high
                </Text>

                {metrics.map((item) => (
                  <Text key={item.yearHigh} size="sm">
                    {parseFloat(item.yearHigh).toFixed(2)}
                  </Text>
                ))}
              </div>
            </GridCol>
            <GridCol span={{ base: 6, md: 6, lg: 6 }}>
              <div>
                <Text c="dimmed" size="sm">
                  52-wk low
                </Text>

                {metrics.map((item) => (
                  <Text key={item.yearHigh} size="sm">
                    {parseFloat(item.yearLow).toFixed(2)}
                  </Text>
                ))}
              </div>
            </GridCol>
            <GridCol span={{ base: 6, md: 6, lg: 6 }}>
              <div>
                <Text c="dimmed" size="sm">
                  Average volume
                </Text>

                {metrics.map((item) => (
                  <Text key={item.yearHigh} size="sm">
                    {item.volume.toLocaleString()}
                  </Text>
                ))}
              </div>
            </GridCol>
          </Grid>
        </GridCol>
      </Grid>
    </div>
  ));

  return (
    <>
      <Grid>
        <GridCol span={{ base: 12, md: 6, lg: 7 }}>
          <Grid>
            <GridCol span={{ base: 12, md: 12, lg: 12 }}>
              <SearchTicker fetchCompanyProfile={fetchCompanyOutlook} />
              <Space h="sm" />
              <Box pos="relative">
                <LoadingOverlay
                  visible={loading}
                  zIndex={1000}
                  overlayProps={{ radius: "sm", blur: 2 }}
                  loaderProps={{ type: "bars" }}
                />
                {data.length > 0 && <Card withBorder>{data}</Card>}
              </Box>
            </GridCol>

            {userSelectedSymbol.length !== 0 && (
              <GridCol span={{ base: 12, md: 12, lg: 12 }}>
                <Box pos="relative">
                  <LoadingOverlay
                    visible={loading}
                    zIndex={1000}
                    overlayProps={{ radius: "sm", blur: 2 }}
                    loaderProps={{ type: "bars" }}
                  />
                  <GetInsiderTransactions
                    userSelectedSymbol={userSelectedSymbol}
                  />
                </Box>
              </GridCol>
            )}
          </Grid>
        </GridCol>

        <GridCol span={{ base: 12, md: 4, lg: 5 }}>
          <Grid>
            <GridCol span={{ base: 12, md: 12, lg: 12 }}>
              <GetEconomicCal />
            </GridCol>
            <GridCol span={{ base: 12, md: 12, lg: 12 }}>
              <Box pos="relative">
                <LoadingOverlay
                  visible={loading}
                  zIndex={1000}
                  overlayProps={{ radius: "sm", blur: 2 }}
                  loaderProps={{ type: "bars" }}
                />
                <CompanyNews stockNews={stockNews} />
              </Box>
            </GridCol>
          </Grid>
        </GridCol>
      </Grid>
    </>
  );
}

export default GetFullQuote;

import {
  Grid,
  Title,
  Card,
  Table,
  Button,
  Group,
  ButtonGroup,
  Input,
  NumberFormatter,
  Box,
  LoadingOverlay,
  Flex,
  ActionIcon,
  Menu,
  Divider,
  Text,
  Stack,
  Image,
  ScrollArea,
  Space,
} from "@mantine/core";

import {
  IconCheck,
  IconFilter,
  IconFilterFilled,
  IconSearch,
} from "@tabler/icons-react";
import moment from "moment";
import { useEffect, useState } from "react";
import image from "../assets/file-folder.png";
import XSmallText from "../components/XSmallText";

const key = process.env.REACT_APP_FMP_API;

// const userTimezone = new Date()
//   .toLocaleDateString(undefined, { day: "2-digit", timeZoneName: "short" })
//   .substring(4);

export default function GetInsiderTransactions({ userSelectedSymbol }) {
  console.log(userSelectedSymbol);
  // const [apiData, setApiData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activePage, setLoadMore] = useState(0);
  const [symbol, setSymbol] = useState("");
  const [invalidSearch, setInvalidSearch] = useState(false);
  const [filterBuy, setFilterBuy] = useState(false);
  const [filterSell, setFilterSell] = useState(false);

  const handleFilterBuy = () => {
    setFilterBuy((prev) => !prev);
    setFilterSell(false);
  };
  const handleFilterSell = () => {
    setFilterSell((prev) => !prev);
    setFilterBuy(false);
  };
  const clearFilter = () => {
    setFilterSell(false);
    setFilterBuy(false);
  };

  const handleClick = (link) => {
    window.open(link, "_blank");
  };

  const handleLoadMore = () => {
    setLoadMore(activePage + 1);
    fetchSearchBySymbol();
  };
  const handleReset = () => {
    setLoadMore(0);
  };

  useEffect(() => {
    fetchSearchBySymbol();
  }, [activePage]);

  const handleInput = (input) => {
    setInvalidSearch(false);
    const upper = input?.toUpperCase();
    setSymbol(upper);

    fetchSearchBySymbol();
  };

  async function fetchSearchBySymbol() {
    setLoading(true);
    try {
      const response = await fetch(
        `https://financialmodelingprep.com/api/v4/insider-trading?symbol=${userSelectedSymbol}&page=0&apikey=${key}`,
        {
          method: "GET",
        }
      );
      const res = await response.json();
      setSearchData(res);
      console.log("Insiders");
    } catch (error) {
      console.log(error);
      setInvalidSearch(true);
    }

    setLoading(false);
  }

  useEffect(() => {
    fetchSearchBySymbol();
  }, [userSelectedSymbol]);

  const rowsSearchData = searchData.map((item, index) =>
    filterBuy && item.acquistionOrDisposition === "A" ? (
      <Table.Tr
        style={{ cursor: "pointer" }}
        key={index}
        onClick={() => handleClick(item.link)}
      >
        <Table.Td>{moment(item.filingDate).format("ddd, MM/DD/YYYY")}</Table.Td>

        <Table.Td>
          <XSmallText>{item.reportingName}</XSmallText>
        </Table.Td>
        <Table.Td>{item.typeOfOwner}</Table.Td>
        <Table.Td>{item.acquistionOrDisposition === "A" && "Buy"}</Table.Td>
        <Table.Td>
          <NumberFormatter
            prefix="$"
            thousandSeparator
            decimalScale={2}
            value={item.price}
          />
        </Table.Td>
        <Table.Td>
          <NumberFormatter
            thousandSeparator
            value={item.securitiesTransacted}
          />
        </Table.Td>
        <Table.Td>
          <NumberFormatter
            prefix="$"
            thousandSeparator
            decimalScale={2}
            value={
              parseFloat(item.price).toFixed(2) * item.securitiesTransacted
            }
          />
        </Table.Td>
        <Table.Td>
          <NumberFormatter thousandSeparator value={item.securitiesOwned} />
        </Table.Td>
      </Table.Tr>
    ) : filterSell && item.acquistionOrDisposition === "D" ? (
      <Table.Tr
        style={{ cursor: "pointer" }}
        key={index}
        onClick={() => handleClick(item.link)}
      >
        <Table.Td>
          <XSmallText>
            {moment(item.filingDate).format("MM/DD/YYYY")}
          </XSmallText>
        </Table.Td>
        <Table.Td>
          <XSmallText>
            {moment(item.transactionDate).format("MM/DD/YYYY")}
          </XSmallText>
        </Table.Td>

        <Table.Td>
          <XSmallText>{item.reportingName}</XSmallText>
        </Table.Td>
        <Table.Td>{item.typeOfOwner}</Table.Td>
        <Table.Td>{item.acquistionOrDisposition === "D" && "Sell"}</Table.Td>
        <Table.Td>
          <NumberFormatter
            prefix="$"
            thousandSeparator
            decimalScale={2}
            value={item.price}
          />
        </Table.Td>
        <Table.Td>
          <NumberFormatter
            thousandSeparator
            value={item.securitiesTransacted}
          />
        </Table.Td>
        <Table.Td>
          <NumberFormatter
            prefix="$"
            thousandSeparator
            decimalScale={2}
            value={
              parseFloat(item.price).toFixed(2) * item.securitiesTransacted
            }
          />
        </Table.Td>
        {/* <Table.Td>
          <NumberFormatter thousandSeparator value={item.securitiesOwned} />
        </Table.Td> */}
      </Table.Tr>
    ) : (
      !filterBuy &&
      !filterSell && (
        <Table.Tr
          style={{ cursor: "pointer" }}
          key={index}
          onClick={() => handleClick(item.link)}
        >
          <Table.Td>
            <XSmallText>
              {moment(item.filingDate).format("MM/DD/YYYY")}
            </XSmallText>
          </Table.Td>
          <Table.Td>
            <Table.Td>
              <XSmallText>
                {moment(item.transactionDate).format("MM/DD/YYYY")}
              </XSmallText>
            </Table.Td>
          </Table.Td>

          <Table.Td>
            <XSmallText>{item.reportingName}</XSmallText>
          </Table.Td>
          <Table.Td>
            <XSmallText>{item.typeOfOwner}</XSmallText>
          </Table.Td>
          <Table.Td>
            <XSmallText>
              {item.acquistionOrDisposition === "A" ? "Buy" : "Sell"}
            </XSmallText>
          </Table.Td>
          <Table.Td>
            <XSmallText>
              <NumberFormatter
                prefix="$"
                thousandSeparator
                decimalScale={2}
                value={item.price}
              />
            </XSmallText>
          </Table.Td>
          <Table.Td>
            <XSmallText>
              <NumberFormatter
                thousandSeparator
                value={item.securitiesTransacted}
              />
            </XSmallText>
          </Table.Td>
          <Table.Td>
            <XSmallText>
              <NumberFormatter
                prefix="$"
                thousandSeparator
                decimalScale={2}
                value={
                  parseFloat(item.price).toFixed(2) * item.securitiesTransacted
                }
              />
            </XSmallText>
          </Table.Td>
        </Table.Tr>
      )
    )
  );

  return (
    <>
      <Card withBorder h={800}>
        <Title order={5} c="dimmed">
          Insider Trades
        </Title>
        <Space h="lg" />
        <ScrollArea h={800}>
          <Box pos="relative">
            <LoadingOverlay
              visible={loading}
              zIndex={1000}
              overlayProps={{ radius: "sm", blur: 2 }}
            />

            <Table.ScrollContainer>
              <Table striped highlightOnHover>
                <Table.Thead>
                  {searchData.length !== 0 && (
                    <Table.Tr>
                      <Table.Th>
                        <XSmallText>Reported</XSmallText>
                      </Table.Th>
                      <Table.Th>
                        <XSmallText>Transacted</XSmallText>
                      </Table.Th>

                      <Table.Th>
                        <XSmallText>Name</XSmallText>
                      </Table.Th>
                      <Table.Th>
                        <XSmallText>Title</XSmallText>
                      </Table.Th>
                      <Table.Th>
                        <Flex align="center">
                          <XSmallText>Buy/Sell</XSmallText>
                          <Menu shadow="md">
                            <Menu.Target>
                              <ActionIcon ml={3} variant="subtle">
                                {!filterBuy && !filterSell ? (
                                  <IconFilter size={16} />
                                ) : (
                                  <IconFilterFilled size={16} />
                                )}
                              </ActionIcon>
                            </Menu.Target>
                            <Menu.Dropdown>
                              <Menu.Label>Filter settings</Menu.Label>
                              <Menu.Item
                                disabled={filterBuy}
                                rightSection={
                                  filterBuy && <IconCheck size={16} />
                                }
                                onClick={handleFilterBuy}
                              >
                                Buy
                              </Menu.Item>
                              <Menu.Item
                                disabled={filterSell}
                                rightSection={
                                  filterSell && <IconCheck size={16} />
                                }
                                onClick={handleFilterSell}
                              >
                                Sell
                              </Menu.Item>
                              <Divider />
                              <Menu.Item
                                disabled={!filterBuy && !filterSell}
                                onClick={clearFilter}
                              >
                                Clear filter
                              </Menu.Item>
                            </Menu.Dropdown>
                          </Menu>
                        </Flex>
                      </Table.Th>
                      <Table.Th>
                        <XSmallText>Price</XSmallText>
                      </Table.Th>
                      <Table.Th>
                        <XSmallText>Securities</XSmallText>
                      </Table.Th>
                      <Table.Th>
                        <XSmallText>Total</XSmallText>
                      </Table.Th>
                    </Table.Tr>
                  )}
                </Table.Thead>
                <Table.Tbody>{rowsSearchData}</Table.Tbody>
              </Table>
            </Table.ScrollContainer>

            {/* {searchData.length !== 0 && (
              <ButtonGroup>
                <Button variant="subtle" onClick={handleLoadMore}>
                  Load more
                </Button>
                <Button variant="subtle" onClick={handleReset}>
                  Reset
                </Button>
              </ButtonGroup>
            )} */}
          </Box>
        </ScrollArea>
      </Card>
    </>
  );
}

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { UserProvider } from "./contexts/user.context";
import Home from "./pages/Home.page";
import Login from "./pages/Login.page";
import PrivateRoute from "./pages/PrivateRoute.page";
import { useMantineColorScheme } from "@mantine/core";
import { useEffect, useState } from "react";

function App() {
  const { setColorScheme } = useMantineColorScheme();
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    if (checked) {
      setColorScheme("dark");
    } else {
      setColorScheme("dark");
    }
  }, [checked]);

  return (
    <BrowserRouter>
      {/* We are wrapping our whole app with UserProvider so that */}
      {/* our user is accessible through out the app from any page*/}
      <UserProvider>
        <Routes>
          <Route exact path="/" element={<Login />} />

          {/* We are protecting our Home Page from unauthenticated */}
          {/* users by wrapping it with PrivateRoute here. */}
        </Routes>
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;

import { Card, Center, Flex, Space, Text } from "@mantine/core";
import {
  Authenticator,
  ThemeProvider,
  useAuthenticator,
} from "@aws-amplify/ui-react";

import "@aws-amplify/ui-react/styles.css";
import Home from "./Home.page";
import { confirmSignIn } from "aws-amplify/auth";

const Login = () => {
  const theme = {
    name: "Auth Example Theme",

    tokens: {
      components: {
        authenticator: {
          router: {
            boxShadow: `rgba(0, 0, 0, 0) 0px 3px 8px`,
            borderWidth: 0,
            backgroundColor: "transparent",
          },
          form: {
            padding: "4rem",
            background: "unset",
            color: { value: "grey" },
          },
        },
        text: {
          color: { value: "white" },
        },
        font: {
          primary: "white",
          secondary: "white",
          interactive: {
            value: "grey",
          },
        },

        button: {
          color: "grey",
          _focus: {
            color: "lightgrey",
          },
          _active: {
            backgroundColor: { value: "#297d39" },
          },
          _hover: {
            backgroundColor: { value: "#484848" },
            color: { value: "grey" },
          },
          primary: {
            backgroundColor: { value: "{colors.green.60}" },
            _hover: {
              backgroundColor: { value: "{colors.green.80}" },
            },
            _focus: {
              backgroundColor: { value: "{colors.green.80}" },
            },
            _active: {
              backgroundColor: { value: "{colors.green.90}" },
            },
            _disabled: {
              backgroundColor: { value: "{colors.green.90}" },
            },
            error: {
              backgroundColor: { value: "{colors.pink.10}" },
              color: { value: "{colors.red.80}" },
              _hover: {
                backgroundColor: { value: "#a51b34" },
              },
              _focus: {
                backgroundColor: { value: "#9a0c26" },
              },
              _active: {
                backgroundColor: { value: "#9a0c26" },
              },
            },
          },
          link: {
            color: "#309e45",
          },
        },
        fieldcontrol: {
          color: "lightgrey",
          _focus: {
            boxShadow: `0 0 0 1px #309e45`,
          },
        },
      },
    },
  };

  const formFields = {
    setupTotp: {
      QR: {
        totpIssuer: "Insight Terminal",
      },
      confirmation_code: {
        label: "",
        placeholder: "Enter your MFA code",
        isRequired: false,
      },
    },
    signIn: {
      username: {
        placeholder: "Email",
        isRequired: true,
        label: "",
      },
      password: {
        placeholder: "Password",
        isRequired: true,
        label: "",
      },
    },
    confirmSignIn: {
      confirmation_code: {
        label: "",
        placeholder: "Enter your MFA code",
        isRequired: false,
      },
    },
  };

  const components = {
    ConfirmResetPassword: {
      Header() {
        return <Text>Header Information</Text>;
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
    SetupTotp: {
      Header() {
        return <Text>Header Information</Text>;
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
    ConfirmSignIn: {
      Header() {
        return (
          <div style={{ margin: "10rem 0px 20px" }}>
            <Text fz="sm">
              Use your mobile authenticator app to obtain your time-based MFA
              code for Insight Terminal. Enter the code without spaces (e.g.
              XXXXXX).
            </Text>
          </div>
        );
      },
      Footer() {
        return (
          <Text fz="sm">
            Need help? Contact us at support@insightterminal.com
          </Text>
        );
      },
    },
    SignIn: {
      Header() {
        return (
          <div style={{ margin: "10rem 0px -20px" }}>
            <Flex align="center" justify="center">
              <Text fz="30" fw={500}>
                Insight
              </Text>
              <Text c="green" fw={500} fz={30}>
                Terminal
              </Text>
            </Flex>
            <Flex align="center" justify="center">
              <Text c="dimmed">Sign in with your account</Text>
            </Flex>
          </div>
        );
      },
      ForgotPassword: {
        Header() {
          return <Text>Header Information</Text>;
        },
        Footer() {
          return <Text>Footer Information</Text>;
        },
      },
      ConfirmResetPassword: {
        Header() {
          return <Text>Header Information</Text>;
        },
        Footer() {
          return <Text>Footer Information</Text>;
        },
      },
      ForceNewPassword: {
        FormFields() {
          return (
            <>
              <div style={{ margin: "150px 0px 20px" }}>
                <Authenticator.ForceNewPassword.FormFields />
              </div>
            </>
          );
        },
      },
      Footer() {
        const { toForgotPassword } = useAuthenticator();
        return (
          <>
            {/* <Button variant="subtle" onClick={toForgotPassword}>
                Forgot Password?
              </Button> */}
          </>
        );
      },
    },
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Authenticator
          hideSignUp
          components={components}
          formFields={formFields}
        >
          {({ signOut, user }) => <Home signOut={signOut} user={user} />}
        </Authenticator>
      </ThemeProvider>
    </>
  );
};

export default Login;

import {
  Title,
  Text,
  Card,
  Badge,
  Table,
  Button,
  Group,
  Box,
  LoadingOverlay,
  ScrollArea,
  Space,
} from "@mantine/core";
import { IconArrowDown, IconArrowUp } from "@tabler/icons-react";
import moment from "moment";
import { useEffect, useState } from "react";

const key = process.env.REACT_APP_FMP_API;

const userTimezone = new Date()
  .toLocaleDateString(undefined, { day: "2-digit", timeZoneName: "short" })
  .substring(4);

let currentDate = moment().format("YYYY-MM-DD");
let oneMonthFrom = moment().add(1, "months").format("YYYY-MM-DD");
let oneWeekAgo = moment().add(-5, "weeks").format("YYYY-MM-DD");
let oneWeekFrom = moment().add(1, "weeks").format("YYYY-MM-DD");
let tomorrow = moment().add(1, "days").format("YYYY-MM-DD");

// console.log(currentDate + " " + oneMonthAgo);

export default function GetEconomicCal() {
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAll, setShowAll] = useState(false);

  async function fetchCurrent() {
    setLoading(true);
    try {
      const response = await fetch(
        `https://financialmodelingprep.com/api/v3/economic_calendar?from=${currentDate}&to=${oneWeekFrom}&apikey=${key}`,
        {
          method: "GET",
        }
      );
      const res = await response.json();
      setApiData(res.reverse());
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }
  async function fetchOneWeek() {
    setLoading(true);
    try {
      const response = await fetch(
        `https://financialmodelingprep.com/api/v3/economic_calendar?from=${oneWeekAgo}&to=${oneWeekFrom}&apikey=${key}`,
        {
          method: "GET",
        }
      );
      const res = await response.json();
      setApiData(res.reverse());
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }
  async function fetchOneMonth() {
    try {
      const response = await fetch(
        `https://financialmodelingprep.com/api/v3/economic_calendar?from=${currentDate}&to=${oneMonthFrom}&apikey=${key}`,
        {
          method: "GET",
        }
      );
      const res = await response.json();
      setApiData(res.reverse());
    } catch (error) {
      console.log(error);
    }
  }

  //keys:
  // change
  // changePercentage
  // country
  // date
  // estimate
  // event
  // impact
  // previous
  // unit

  // <Text size="sm">
  //                   {moment(item.date).format("dddd, MM/DD hh:mm", true)}
  //                 </Text>

  const rows = apiData.map(
    !showAll
      ? (item, index) =>
          item.country === "US" &&
          item.impact === "High" && (
            <Table.Tr key={index}>
              <Table.Td>
                {moment.utc(item.date).local().format("dddd, M/DD") ===
                moment.utc(item.currentDate).local().format("dddd, M/DD") ? (
                  <Text fz="xs" c="green" fw={600}>
                    {moment
                      .utc(item.date)
                      .local()
                      .format("M/DD - h:mm A (ddd)")}
                  </Text>
                ) : (
                  <Text fz="xs">
                    {moment
                      .utc(item.date)
                      .local()
                      .format("M/DD - h:mm A (ddd)")}
                  </Text>
                )}
              </Table.Td>
              {/* <Table.Td>{moment.utc(item.date).local().format("hh:mm A")}</Table.Td> */}
              <Table.Td>
                <Text fz="xs">{item.event}</Text>
              </Table.Td>
              <Table.Td>
                <Text fz="xs">{item.impact}</Text>
              </Table.Td>
              <Table.Td>
                {item.estimate && (
                  <Text fz="xs">
                    {item.estimate}
                    {item.unit}
                  </Text>
                )}
              </Table.Td>
              <Table.Td>
                <Text fz="xs">
                  {item.previous}
                  {item?.unit}
                </Text>
              </Table.Td>
              <Table.Td>
                {item.actual && (
                  <Text fz="xs">
                    {item.actual}
                    {item.unit}
                  </Text>
                )}
              </Table.Td>
            </Table.Tr>
          )
      : (item, index) =>
          item.country === "US" && (
            <Table.Tr key={index}>
              <Table.Td>
                {moment.utc(item.date).local().format("dddd, M/DD") ===
                moment.utc(item.currentDate).local().format("dddd, M/DD") ? (
                  <Text fz="xs" c="green" fw={600}>
                    {moment
                      .utc(item.date)
                      .local()
                      .format("M/DD - h:mm A (ddd)")}
                  </Text>
                ) : (
                  <Text fz="xs">
                    {moment
                      .utc(item.date)
                      .local()
                      .format("M/DD - h:mm A (ddd)")}
                  </Text>
                )}
              </Table.Td>
              <Table.Td>
                <Text fz="xs">{item.event}</Text>
              </Table.Td>
              <Table.Td>
                <Text fz="xs">{item.impact}</Text>
              </Table.Td>
              <Table.Td>
                {item.estimate && (
                  <Text fz="xs">
                    {item.estimate}
                    {item.unit}
                  </Text>
                )}
              </Table.Td>
              <Table.Td>
                <Text fz="xs">
                  {item.previous}
                  {item?.unit}
                </Text>
              </Table.Td>
              <Table.Td>
                {item.actual && (
                  <Text fz="xs">
                    {item.actual}
                    {item.unit}
                  </Text>
                )}
              </Table.Td>
            </Table.Tr>
          )
  );

  useEffect(() => {
    fetchCurrent();
  }, []);

  return (
    <>
      <Card withBorder h={"100%"}>
        <Group size="xs">
          <Title order={5} c="dimmed">
            U.S. Economic Calendar
          </Title>
          <Badge size="xs" variant="light">
            Weekly
          </Badge>
        </Group>
        <Space h="lg" />
        <ScrollArea mah={300}>
          <Box pos="relative">
            <LoadingOverlay
              visible={loading}
              zIndex={1000}
              overlayProps={{ radius: "sm", blur: 2 }}
            />

            <Table.ScrollContainer>
              <Table>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>
                      <Text fz="xs">Time ({userTimezone})</Text>
                    </Table.Th>
                    <Table.Th>
                      <Text fz="xs">Event</Text>
                    </Table.Th>
                    <Table.Th>
                      <Text fz="xs">Impact</Text>
                    </Table.Th>
                    <Table.Th>
                      <Text fz="xs">Estimate</Text>
                    </Table.Th>
                    <Table.Th>
                      <Text fz="xs">Previous</Text>
                    </Table.Th>
                    <Table.Th>
                      <Text fz="xs">Actual</Text>
                    </Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {rows}

                  <Table.Tr>
                    {showAll ? (
                      <Text
                        size="xs"
                        variant="subtle"
                        rightSection={<IconArrowUp size={14} />}
                        onClick={() => setShowAll(false)}
                      >
                        Show less
                      </Text>
                    ) : (
                      <Text
                        size="xs"
                        variant="subtle"
                        rightSection={<IconArrowDown size={14} />}
                        onClick={() => setShowAll(true)}
                      >
                        Show all
                      </Text>
                    )}
                  </Table.Tr>
                </Table.Tbody>

                <Table.Caption>
                  This week's U.S. economic reports and Fed speakers
                </Table.Caption>
              </Table>
            </Table.ScrollContainer>
          </Box>
        </ScrollArea>
      </Card>
    </>
  );
}

import {
  ActionIcon,
  Button,
  Flex,
  Group,
  Input,
  Kbd,
  Menu,
  Text,
} from "@mantine/core";
import { IconClearAll, IconSearch, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";

const key = process.env.REACT_APP_FMP_API;

function SearchTicker({ fetchCompanyProfile }) {
  const [searchCriteria, setSearchCriteria] = useState("");
  const [data, setData] = useState([]);
  const [opened, setOpened] = useState(false);

  async function fetchData() {
    try {
      const response = await fetch(
        `https://financialmodelingprep.com/api/v3/search?query=${searchCriteria}&limit=10&exchange=NASDAQ&exchange=NYSE&apikey=${key}`,
        {
          method: "GET",
        }
      );
      const res = await response.json();
      setData(res);
    } catch (error) {
      console.log(error);
    }
  }

  const handleEnterKey = (e) => {
    if (e.keyCode === 13) {
      fetchData();
      setOpened(true);
    }
  };
  const handleClicked = (clickedSymbol) => {
    fetchCompanyProfile(clickedSymbol);
    setOpened(false);
  };

  useEffect(() => {
    if (searchCriteria.length === 0) {
      setData([]);
      setOpened(false);
    }
  }, [searchCriteria]);

  return (
    <Menu opened={opened} position="bottom-start">
      <Menu.Target>
        <Input
          placeholder="Search ticker or company"
          leftSection={<IconSearch />}
          value={searchCriteria}
          onChange={(e) => setSearchCriteria(e.target.value)}
          onKeyDown={(e) => handleEnterKey(e)}
        />
      </Menu.Target>

      <Menu.Dropdown>
        {data.length === 0 ? (
          <Menu.Item disabled>
            <Text c="white" size="sm">
              No results for {searchCriteria}
            </Text>
          </Menu.Item>
        ) : (
          data?.map((item) => (
            <Menu.Item
              key={item.symbol}
              onClick={() => handleClicked(item.symbol)}
            >
              {item.name} ({item.symbol})
            </Menu.Item>
          ))
        )}
      </Menu.Dropdown>
    </Menu>
  );
}

export default SearchTicker;
